import {useState, useEffect} from 'react';
import './App.css';
import axios from 'axios';

const apiUrl = `http://${window.location.hostname}:8000/api/v1/todos/`;
console.log(window.location);
function Title(props) {
  return (
    <div>
       <div>
          <h1>To do ({props.todoCount})</h1>
       </div>
    </div>
  );
}

function TodoForm(props) {
  // Input Tracker
  let input;
  // Return JSX
  return (
    <form onSubmit={(e) => {
        e.preventDefault();
        props.addTodo(input.value);
        input.value = '';
      }}>
      <input placeholder="Type something" className="form-control" ref={node => {
        input = node;
      }} />
      <br />
    </form>
  );
};

function Todo(props) {
  // Each Todo
  return (<li><p className="list-group-item" onClick={() => {props.remove(props.todo.id)}}>{props.todo.text}</p></li>);
}

function TodoList(props) {
  // Map through the todos
  const todoNode = props.todos.map((todo) => {
    return (<Todo todo={todo} key={todo.id} remove={props.remove}/>)
  });
  return (<ul className="list-group" style={{marginTop:'30px'}}>{todoNode}</ul>);
}


function App() {
  const [todos, setTodos] = useState([]);
  const fetchTodos = () => {
    axios.get(apiUrl)
    .then(res => {
      // Set state with result
      setTodos(res.data);
    });
  }
  useEffect(() => {
    fetchTodos();
  }, []);

  const addTodo = (todo) => {
    axios.post(apiUrl, {text:todo})
    .then(res => {
      fetchTodos();
    });
  }
  const removeTodo = (todo) => {
    axios.delete(`${apiUrl}${todo}`)
    .then(res => {
      console.log(res);
      fetchTodos();
    });
  }
  return (
    <div className="App">
        <Title todoCount={todos.length}/>
        <TodoForm addTodo={addTodo}/>
        <TodoList
          todos={todos}
          remove={removeTodo}
        />
    </div>
  );
}

export default App;
